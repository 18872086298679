<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar class="px-1 toolbar-title">
        <ion-buttons class="mr-2" slot="start">
          <ion-back-button
            color="primary"
            mode="ios"
            text=""
            :default-href="userType === 'b2b' ? '/b2b/order' : '/sale/order'"
            @click="goBack"
            :icon="chevronBackOutline"
          ></ion-back-button>
        </ion-buttons>
        <ion-label class="head-title">{{ setOrderBookingHeader }} #{{ orderId }}</ion-label>
      </ion-toolbar>
      <div class="grey-lines" />
    </ion-header>
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <!-- show pull refresh page when no internet connection -->
      <pull-refresh v-if="isConnectionIssue"></pull-refresh>
      <!-- Skeleton Loading -->
      <div v-if="showSkeletonLoading">
        <order-skeleton-loading class="mx-3 my-3" />
      </div>
      <!-- Show Order Detail -->
      <div v-else>
        <div v-if="orderDetails && !isOrderIdNotFound && !isConnectionIssue">
          <div class="pa-2">
            <!-- Order status cheap  -->
            <order-status-cheap
              :orderStatusDetail="orderDetails"
              :isBookingOrder="isBookingOrder"
              :parent="'orderDetail'"
              :userType="userType"
              :orderId="orderId"
            />

            <!-- Order status logs info -->
            <ion-row class="mt-2">
              <ion-col size="auto" class="mr-2 column center-items">
                <div class="time-line" :class="timeLineDot" />
                <div class="line-story" :class="timeLine" />
              </ion-col>
              <ion-col class="column">
                <ion-text class="mb-1 mini-info" :class="orderInfoColor">{{ orderStatusInfo }}</ion-text>
                <ion-text class="text-grey mini-info">{{ lastUpdateDateTime }} </ion-text>
                <!-- order status reason or note -->
                <div v-if="showOrderStatusNoteReason" class="px-2 py-2 po-number-and-note-reason mt-2">
                  <div class="column">
                    <ion-text class="po-text mb-1"> {{ titleNoteReason }}</ion-text>
                    <ion-text> {{ noteAndReason(orderDetails?.last_order_log_info) }}</ion-text>
                  </div>
                </div>
                <!-- View driver map location button -->
                <ion-item
                  v-if="
                    showDriverMapLocation &&
                    orderDetails?.last_order_log_info?.tracking_link &&
                    !checkEmptyLink(orderDetails?.last_order_log_info?.tracking_link)
                  "
                  class="view-driver-location-btn"
                  button
                  detail="false"
                  lines="none"
                  @click="setTrackDriverLocation(true, orderDetails?.last_order_log_info?.tracking_link)"
                >
                  <ion-icon
                    class="mr-2"
                    style="width: 24px; height: 24px"
                    :icon="'/assets/images/map-location.svg'"
                  />
                  <ion-text class="text-primary">{{ $t('view_driver_location') }}</ion-text>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-button class="mt-3 track-order-btn" expand="block" fill="clear" @click="openTrackOrderPage">
              <ion-icon
                class="mr-2"
                style="width: 24px; height: 24px"
                :icon="'/assets/images/track-order.svg'"
              />
              {{ !isBookingOrder ? $t('track_order') : $t('track_booking') }}
            </ion-button>
          </div>
          <div class="content-space"></div>
          <!-- Order or Booking items -->
          <div>
            <div class="tenant-name text-primary my-3 mx-2">
              {{ orderItems?.length > 0 ? orderItems[0].tenant_name : '-' }}
            </div>
            <div class="grey-lines mb-2" />
            <div>
              <OrderItem :orderItems="orderItems" :currencySymbol="currencySymbol" />
            </div>
          </div>
          <div class="content-space"></div>
          <!-- Total and Subtotal price info -->
          <div class="pa-2">
            <div
              v-if="
                !isAllItemsAreTaxInclusive ||
                deliveryFeeItem?.total_price > 0 ||
                discountItem?.total_price < 0
              "
            >
              <ion-row class="text-grey">
                <ion-col class="text-start">
                  {{ $t('sub_total') }}
                  <span class="ion-text-lowercase" v-if="isAllItemsAreTaxInclusive">
                    ({{ $t('tax_inclusive') }})
                  </span>
                </ion-col>
                <ion-col class="text-end">{{ orderDetails?.display_subtotal }}</ion-col>
              </ion-row>
              <ion-row class="text-grey" v-if="!isPickupOrder">
                <ion-col class="text-start">
                  {{ $t('delivery_fees') }}
                  <span class="ion-text-lowercase" v-if="deliveryFeeItem?.total_price > 0">
                    ({{
                      deliveryFeeItem?.price_is_tax_inclusive ? $t('tax_inclusive') : $t('tax_exclusive')
                    }})
                  </span>
                </ion-col>
                <ion-col class="text-end">{{ orderDetails?.display_delivery_fee }}</ion-col>
              </ion-row>
              <ion-row class="text-grey">
                <ion-col class="text-start">
                  {{ $t('discount') }}
                  <span class="ion-text-lowercase" v-if="discountItem?.total_price < 0">
                    ({{ discountItem?.price_is_tax_inclusive ? $t('tax_inclusive') : $t('tax_exclusive') }})
                  </span>
                </ion-col>
                <ion-col class="text-end">{{ orderDetails?.display_discount }}</ion-col>
              </ion-row>
              <ion-row class="text-grey" v-if="!isAllItemsAreTaxInclusive">
                <ion-col class="text-start">{{ $t('tax') }}</ion-col>
                <ion-col class="text-end">{{ orderDetails?.display_total_tax }}</ion-col>
              </ion-row>
              <div class="grey-lines my-2" />
            </div>
            <ion-row class="total-price">
              <ion-col class="text-start">{{ $t('grand_total') }}</ion-col>
              <ion-col class="text-end">{{ orderDetails?.display_grand_total }}</ion-col>
            </ion-row>
          </div>
          <div class="content-space"></div>
          <!-- Delivery information -->
          <div v-if="!isBookingOrder" class="pa-2">
            <ion-text class="address-info">{{ $t('delivery_information') }}</ion-text>
            <ion-row class="my-3">
              <ion-icon
                class="mr-2"
                style="width: 24px; height: 24px"
                :icon="'/assets/images/track-order.svg'"
              />
              <ion-col class="column">
                <ion-text class="mb-1"> {{ deliveryDate }} </ion-text>
                <ion-text class="text-grey mini-info">{{ orderDetails?.delivery_time }}</ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-icon
                class="mr-2"
                style="width: 24px; height: 24px"
                :icon="'/assets/images/location-outline.svg'"
              />
              <ion-col class="column">
                <ion-text class="mb-1">
                  {{ customerInfo }}
                </ion-text>
                <ion-text class="text-grey mini-info">{{ orderDetails?.delivery_address }}</ion-text>
              </ion-col>
            </ion-row>
            <div class="py-2">
              <DeliveryInstructionArea
                :instruction="orderDetails?.description"
                :isPaid="true"
                :idModal="'viewOrder'"
              />
            </div>
          </div>
          <div class="content-space"></div>
          <!-- Billing information -->
          <div class="pa-2">
            <ion-text class="address-info">{{ $t('billing_information') }}</ion-text>
            <ion-row class="my-3" v-if="!cardInfo">
              <ion-icon class="mr-2" style="width: 24px; height: 24px" :icon="setPaymentMethodImage()" />
              <ion-col>
                <ion-text class="mb-1"> {{ paymentMethod }} </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="my-3" v-else>
              <ion-icon
                class="mr-2 mt-2"
                style="width: 24px; height: 24px"
                :icon="getCardImage(cardInfo.brand)"
              />
              <ion-col>
                <div>
                  <ion-text class="mb-1"
                    ><strong>{{ cardInfo.brand }}</strong></ion-text
                  >
                </div>
                <ion-text class="mb-1 text-grey mini-info"
                  >*{{ cardInfo.last4 }} {{ cardInfo.exp_month }}/{{ cardInfo.exp_year }}</ion-text
                >
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-icon
                class="mr-2"
                style="width: 24px; height: 24px"
                :icon="'/assets/images/receipt-address.svg'"
              />
              <ion-col class="column">
                <ion-text>{{ $t('bill_name_and_address') }}</ion-text>
                <ion-text class="my-1 bill-name"> {{ orderDetails?.billing_name }} </ion-text>
                <ion-text class="text-grey mini-info">{{ orderDetails?.billing_address }}</ion-text>
              </ion-col>
            </ion-row>
            <div class="px-2 py-1 po-number-and-note-reason mt-3">
              <div>
                <ion-text class="po-text mr-1"> {{ $t('po_number') }}:</ion-text>
                <ion-text>
                  {{
                    orderDetails?.po_number === null || orderDetails?.po_number === ''
                      ? '-'
                      : orderDetails?.po_number
                  }}</ion-text
                >
              </div>
            </div>
          </div>
          <div class="content-space py-4"></div>
        </div>

        <!-- show not found order -->
        <connection-and-order-not-found
          v-if="isOrderIdNotFound"
          :isOrderIdNotFound="isOrderIdNotFound"
          :userType="userType"
        />
      </div>
    </ion-content>

    <!-- Track driver location -->
    <ion-modal :is-open="isOpenDriverLocation" :backdropDismiss="false" @didDismiss="closeTrackDriverPage">
      <track-driver-location
        :trackingLink="trackingLinkUrl"
        :userType="userType"
        @close-page="closeTrackDriverPage"
      ></track-driver-location>
    </ion-modal>

    <ion-footer v-if="!showSkeletonLoading && !isOrderIdNotFound && !isConnectionIssue">
      <ion-toolbar>
        <div class="ion-padding">
          <ion-row class="ion-align-items-start">
            <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.grandTotal') }} </ion-col>
            <ion-col class="ion-no-padding ion-text-end">
              <ion-text class="grand-total">{{ orderDetails?.display_grand_total }}</ion-text>
            </ion-col>
          </ion-row>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { apolloClient } from '@/main';
import { FLUID_ICON } from '@/modules/b2b/constants';
import { stripe } from '@/modules/b2b/services/libs/payment';
import {
  ACTION_BY_MERCHANT,
  CARD_IMAGE_BRAND,
  ORDER_STATUS_NAME,
  USER_TYPE
} from '@/modules/shared/constants';
import { MAX_32_BIT_SIGNED_INT } from '@/modules/shared/constants/';
import { getOrderDetails } from '@/modules/shared/services/graphql';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils/';
import { useBackButton } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { computed, defineAsyncComponent, defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    // components
    OrderItem: defineAsyncComponent(() => import('./components/OrderItem.vue')),
    DeliveryInstructionArea: defineAsyncComponent(() =>
      import('@/modules/sale/components/AddAndEditDeliveryInstruction/DeliveryInstructionArea.vue')
    ),
    OrderSkeletonLoading: defineAsyncComponent(() => import('./components/OrderSkeletonLoading.vue')),
    OrderStatusCheap: defineAsyncComponent(() => import('./components/OrderStatusCheap.vue')),
    ConnectionAndOrderNotFound: defineAsyncComponent(() =>
      import('./components/ConnectionIssueAndOrderNotFound.vue')
    ),
    TrackDriverLocation: defineAsyncComponent(() => import('./TrackDriverLocation.vue')),
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },
  setup() {
    const orderId = ref('');
    const showSkeletonLoading = ref(true);
    const setSkeletonloading = (state) => (showSkeletonLoading.value = state);
    const router = useRouter();
    const user = ref({});
    const orderDetails = ref({});
    const orderItems = ref([]);
    const deliveryFeeItem = ref({});
    const discountItem = ref({});
    const isOrderIdNotFound = ref(false);
    const isConnectionIssue = ref(false);
    const cardInfo = ref(null);
    const userType = ref('');
    const isCancelledBySupplier = ref(false);
    const { t } = useI18n();
    const { formatDayDateTimeWithCompleteMonthsName, formatDayDate } = useDateFormatter();
    const openTrackOrderPage = () => {
      router.push({
        path:
          userType.value === 'b2b'
            ? `/b2b/order/purchase/track-order/${orderId.value}`
            : `/sale/order/purchase/track-order/${orderId.value}`
      });
    };
    const isOpenDriverLocation = ref(false);
    const trackingLinkUrl = ref('');
    const setTrackDriverLocation = (state, url) => {
      isOpenDriverLocation.value = state;
      trackingLinkUrl.value = url;
      if (state) {
        isBackFromTrackDriver.value = false;
      }
    };

    const $storage = inject('$storage'); // Injecting $storage

    const getuser = async () => {
      user.value = await $storage.getUser();
    };

    const getOrderDetailsData = async (orderId) => {
      const orderIdNumber = parseInt(orderId);
      const withinRange = orderIdNumber > 0 && orderIdNumber <= MAX_32_BIT_SIGNED_INT;
      const isOrderIdValid = orderIdNumber && withinRange;
      try {
        if (!isOrderIdValid) {
          setSkeletonloading(false);
          return (isOrderIdNotFound.value = true);
        }
        const { data, errors } = await apolloClient.query({
          query: getOrderDetails,
          variables: {
            orderId: orderIdNumber
          }
        });

        if (errors && errors.length > 0) {
          // when order is not found
          const errorMessage = errors[0].message;
          isOrderIdNotFound.value = errorMessage === 'Order not found';
        } else {
          orderDetails.value = data.getOrderDetails;
          orderItems.value = data.getOrderDetails.orderItems.filter((item) => !!item.sku_id);
          deliveryFeeItem.value = data.getOrderDetails.orderItems.find((item) => item.is_delivery_fee);
          discountItem.value = data.getOrderDetails.orderItems.find((item) => item.is_discount_item);
          isCancelledBySupplier.value = USER_TYPE.SALE.includes(
            orderDetails.value?.last_order_log_info?.changed_by_user_type
          );
          if (data.getOrderDetails.card_info && data.getOrderDetails.payment_method) {
            cardInfo.value = {
              ...data.getOrderDetails.card_info,
              image: CARD_IMAGE_BRAND[data.getOrderDetails.card_info.brand.toUpperCase()]
            };
          }
          console.log({
            deliveryFeeItem: deliveryFeeItem.value,
            discountItem: discountItem.value
          });
        }
      } catch (error) {
        isConnectionIssue.value =
          error.message === t('load_failed') || error.message === t('failed_to_fetch');
      } finally {
        setSkeletonloading(false);
      }
    };

    const handleRefresh = async (event) => {
      isConnectionIssue.value = false;
      setSkeletonloading(true);
      await getuser();
      await getOrderDetailsData(orderId.value);
      setSkeletonloading(false);
      event.target.complete();
    };

    const isBackFromTrackDriver = ref(false);
    const clearIframeHistoryStateEffect = () => {
      isBackFromTrackDriver.value = false;
      // the <iframe> used inside TrackDriverLocation.vue is effected the history state back and add 1 more state history
      // so now need to use back with 2 step to clear it
      router.go(-2);
    };
    const closeTrackDriverPage = () => {
      setTrackDriverLocation(false, '');
      isBackFromTrackDriver.value = true;
    };

    const goBack = () => {
      const historyState = window.history.state.back;
      const isFromPaymentTrasaction = historyState?.includes('payment-transaction');
      if (isBackFromTrackDriver.value) {
        clearIframeHistoryStateEffect();
      } else {
        isFromPaymentTrasaction ? router.replace('/b2b/order') : router.back();
      }
    };

    // handle when user use hardware back button
    useBackButton(10, () => {
      if (isOpenDriverLocation.value) {
        closeTrackDriverPage();
      } else {
        goBack();
      }
    });

    const customerInfo = computed(() => {
      const customerName = orderItems.value[0]?.customer_name || '-';
      const customerAccountNumber = orderItems.value[0]?.account_number;

      return `${customerName} ${customerAccountNumber ? `[${customerAccountNumber}]` : ''}`;
    });

    // cek order status for set dot timeline style
    const timeLineDot = computed(() => {
      let orderStatus = '';
      if (orderDetails.value?.last_order_log_info) {
        orderStatus = orderDetails.value?.last_order_log_info?.status;
      } else {
        orderStatus = orderDetails.value?.order_status;
      }

      return [ORDER_STATUS_NAME.CANCELLED, ORDER_STATUS_NAME.FAILED_DELIVERY].includes(orderStatus)
        ? 'time-line-red'
        : 'time-line-green';
    });

    // cek order status for set timeline color
    const timeLine = computed(() => {
      let orderStatus = '';
      if (orderDetails.value?.last_order_log_info) {
        orderStatus = orderDetails.value?.last_order_log_info?.status;
      } else {
        orderStatus = orderDetails.value?.order_status;
      }
      return [ORDER_STATUS_NAME.CANCELLED, ORDER_STATUS_NAME.FAILED_DELIVERY].includes(orderStatus)
        ? 'red'
        : 'green';
    });

    // cek order status for set logs info
    const orderStatusInfo = computed(() => {
      let orderStatus = '';
      let logsInfo = orderDetails.value?.last_order_log_info;
      if (logsInfo) {
        orderStatus = logsInfo?.status;
      } else {
        orderStatus = orderDetails.value?.order_status;
      }

      switch (true) {
        // Set status that is order or booking
        case orderStatus === ORDER_STATUS_NAME.CANCELLED && !isCancelledBySupplier.value:
          return t('has_been_cancelled', {
            isOrderBooking: isBookingOrder.value ? t('booking_lowcase') : t('order_lowcase')
          });
        case orderStatus === ORDER_STATUS_NAME.COMPLETED:
          return isBookingOrder.value ? t('booking_has_been_confirmed') : t('order_has_been_completed');
        case orderStatus === ORDER_STATUS_NAME.PROCESSED:
          return (
            orderItems.value[0].tenant_name +
            ' ' +
            t('is_preparing_order', {
              isOrderBooking: isBookingOrder.value ? t('booking_lowcase') : t('order_lowcase')
            })
          );
        case orderStatus === ORDER_STATUS_NAME.FAILED_DELIVERY:
          return t('delivery_failed');
        case orderStatus === ORDER_STATUS_NAME.DELIVERY_SUCCEED ||
          orderStatus === ORDER_STATUS_NAME.DELIVERY_SUCCESSFUL:
          return t('delivered_success', {
            isOrderBooking: isBookingOrder.value ? t('booking_lowcase') : t('order_lowcase')
          });
        case orderStatus === ORDER_STATUS_NAME.DELIVERY_STARTED:
          return t('has_pickup_order', {
            isOrderBooking: isBookingOrder.value ? t('booking_lowcase') : t('order_lowcase')
          });
        case orderStatus === ORDER_STATUS_NAME.CANCELLED && isCancelledBySupplier.value:
          return t('cancelled_by_supplier', {
            isOrderBooking: isBookingOrder.value ? t('booking_lowcase') : t('order_lowcase'),
            supplierName: orderItems.value[0].tenant_name
          });
        case orderStatus === ORDER_STATUS_NAME.BLOCKED:
          return t('order_is_on_hold');
        default:
          return t('you_have_placed_order', {
            isOrderBooking: isBookingOrder.value ? t('booking_lowcase') : t('order_lowcase')
          });
      }
    });

    // cek order status for set logs text color
    const orderInfoColor = computed(() => {
      let orderStatus = '';
      if (orderDetails.value?.last_order_log_info) {
        orderStatus = orderDetails.value?.last_order_log_info?.status;
      } else {
        orderStatus = orderDetails.value?.order_status;
      }
      return [ORDER_STATUS_NAME.CANCELLED, ORDER_STATUS_NAME.FAILED_DELIVERY].includes(orderStatus)
        ? 'text-red'
        : 'text-green';
    });

    // Check to show order reason or note
    const showOrderStatusNoteReason = computed(() => {
      let orderStatus = '';
      let noteAndReasonValue = noteAndReason(orderDetails.value?.last_order_log_info);
      if (orderDetails.value?.last_order_log_info) {
        orderStatus = orderDetails.value?.last_order_log_info?.status;
      } else {
        orderStatus = orderDetails.value?.order_status;
      }
      return (
        ([
          ORDER_STATUS_NAME.DELIVERY_SUCCEED,
          ORDER_STATUS_NAME.DELIVERY_SUCCESSFUL,
          ORDER_STATUS_NAME.FAILED_DELIVERY
        ].includes(orderStatus) ||
          (orderStatus === ORDER_STATUS_NAME.CANCELLED &&
            orderDetails.value?.last_order_log_info?.reason !== ACTION_BY_MERCHANT)) &&
        noteAndReasonValue?.length > 0
      );
    });

    // set note or reason value
    const noteAndReason = (noteReasonParams) => {
      let { reason_type, reason } = noteReasonParams;
      let noteReason = '';

      reason = reason?.trim();
      noteReason = reason ? (reason_type ? `${reason_type}. ${reason}` : reason) : reason_type;
      return noteReason;
    };
    // Set order reason or note title
    const titleNoteReason = computed(() => {
      let orderStatus = '';
      if (orderDetails.value?.last_order_log_info) {
        orderStatus = orderDetails.value?.last_order_log_info?.status;
      } else {
        orderStatus = orderDetails.value?.order_status;
      }
      switch (orderStatus) {
        case ORDER_STATUS_NAME.CANCELLED:
          return t('cancellation_reason');
        case ORDER_STATUS_NAME.FAILED_DELIVERY:
          return t('failure_reason');
        default:
          return t('note');
      }
    });
    // show view driver map location
    const showDriverMapLocation = computed(() => {
      let orderStatus = '';
      if (orderDetails.value?.last_order_log_info) {
        orderStatus = orderDetails.value?.last_order_log_info?.status;
      } else {
        orderStatus = orderDetails.value?.order_status;
      }
      return orderStatus === ORDER_STATUS_NAME.DELIVERY_STARTED;
    });
    const lastUpdateDateTime = computed(() => {
      return formatDayDateTimeWithCompleteMonthsName(orderDetails.value?.last_order_log_info?.created_at);
    });
    const currencySymbol = computed(() => {
      return user.value && user.value?.currency_symbol;
    });
    const deliveryDate = computed(() => {
      return formatDayDate(orderDetails.value?.delivery_date);
    });
    const paymentMethod = computed(() => {
      const payMethod = orderDetails.value?.payment_method;
      return payMethod
        ? payMethod
        : t('credit_term') +
            ': ' +
            (orderDetails.value?.customer_credit_term.displayName
              ? orderDetails.value?.customer_credit_term.displayName
              : '-');
    });
    const isPickupOrder = computed(() => {
      return orderDetails.value?.is_pickup;
    });
    const isBookingOrder = computed(() => {
      return orderDetails.value?.is_booking;
    });
    const setOrderBookingHeader = computed(() => {
      return showSkeletonLoading.value
        ? ''
        : !showSkeletonLoading.value && !isBookingOrder.value
        ? t('orderB2b.order')
        : t('booking');
    });

    const isAllItemsAreTaxInclusive = computed(() => {
      // include delivery fee, discount item
      return (
        orderDetails.value?.orderItems.length &&
        orderDetails.value?.orderItems.every((item) => item.price_is_tax_inclusive)
      );
    });

    const getCardImage = (brand) => {
      return stripe.getCardImage(brand);
    };
    const setPaymentMethodImage = () => {
      const payMethod = orderDetails.value?.payment_method;
      return payMethod ? getCardImage(payMethod) : '/assets/images/payment-method.svg';
    };

    const checkEmptyLink = (trackingLink) => {
      return trackingLink.trim() === '';
    };

    return {
      chevronBackOutline,
      orderId,
      showSkeletonLoading,
      setSkeletonloading,
      user,
      orderDetails,
      orderItems,
      handleRefresh,
      isOrderIdNotFound,
      isConnectionIssue,
      userType,
      priceFormatter,
      isOpenDriverLocation,
      trackingLinkUrl,
      openTrackOrderPage,
      setTrackDriverLocation,
      getuser,
      getOrderDetailsData,
      closeTrackDriverPage,
      isBackFromTrackDriver,
      clearIframeHistoryStateEffect,
      goBack,
      customerInfo,
      timeLineDot,
      timeLine,
      orderStatusInfo,
      orderInfoColor,
      showOrderStatusNoteReason,
      noteAndReason,
      titleNoteReason,
      showDriverMapLocation,
      lastUpdateDateTime,
      currencySymbol,
      deliveryDate,
      paymentMethod,
      cardInfo,
      isPickupOrder,
      isBookingOrder,
      setOrderBookingHeader,
      getCardImage,
      FLUID_ICON,
      setPaymentMethodImage,
      checkEmptyLink,
      isAllItemsAreTaxInclusive,
      deliveryFeeItem,
      discountItem
    };
  },

  async ionViewDidEnter() {
    this.setSkeletonloading(true);
    this.orderId = this.$route.params.order_id;
    const currentPathUrl = window.location.pathname;
    const pathSegments = currentPathUrl.split('/');
    this.userType = pathSegments[1];
    await this.getuser();
    await this.getOrderDetailsData(this.orderId);
  },

  ionViewDidLeave() {
    this.setSkeletonloading(true);
    this.orderDetails = {} || null;
  }
});
</script>

<style src="./style.scss" lang="scss" scoped></style>
